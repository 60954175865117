import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Slot from "./components/slot/slot";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "./media.css";
import { useAuthStore } from "./hooks/auth";
const App = () => {
  const { user, signIn } = useAuthStore(state => state);
  useEffect(() => {
    if (!user) {
      signIn()
    }
  }, [])
  return (
    <div className='App'>
      <div className='main-wrapper'>
        <React.Fragment>
          <HashRouter>
            <Routes>
              <Route path='/' element={<Slot />} />
            </Routes>
          </HashRouter>
        </React.Fragment>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;

