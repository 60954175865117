/* eslint-disable no-useless-concat */
import axios from 'axios';
import { getCookie } from '../util/cookieUtil';
import { landingServer, server } from './keys';

const getAuthorizationToken = () => {
  return getCookie('token') ? `Bearer ${getCookie('token')}` : '';
}

export const slotInstance = () => {
  return axios.create({
    baseURL: `${server}/api/slot`,
    headers: {
      Authorization: getAuthorizationToken(),
    },
    // withCredentials: true,
  });
};

export const slotWithoutInstance = () => {
  return axios.create({
    baseURL: `${server}/api/slot`,
  });
};

export const userInstance =() => axios.create({
  baseURL: `${landingServer}/v1/users`,
  headers: { Authorization: `Bearer ${getCookie("token")}` },
});
