import React from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
// import { Tabs, Tab } from "react-bootstrap";
import copy from "../../assets/images/game/copy-icon.png";
import arrow from "../../assets/images/game/arrow-icon.svg";
// seeds
const FainessPopup = (seeds) => {
  return (
    <div className="fainess-popup-content">
      <div className="limbo-tab">
        <Tabs defaultActiveKey="seeds" id="uncontrolled-tab-example">
          <Tab eventKey="seeds" title="Seeds" className="helo">
            <Seeds seeds={seeds} />
          </Tab>
          <Tab eventKey="varify" title="Verify">
            <Varify
            //   seeds={seeds}
            //   finalResult={seeds.finalResult}
            //   fairnessCalculate={seeds.fairnessCalculate}
            //   handleSetNonce={seeds.handleSetNonce}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default FainessPopup;

const Seeds = (seeds) => {
  return (
    <div className="seeds-content">
      <Form>
        <div className="tab-box">
          <Form.Group>
            <Form.Label>Active Client Seed</Form.Label>
            <div className="limbo-input">
              <Form.Control
                type="text"
                value={seeds?.seeds?.seeds?.clientSeed}
              />
              <div className="limbo-input-btn">
                <Button className="limbo-btn-copy">
                  <img src={copy} alt="copy" />
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Active Server Seed (Hashed)</Form.Label>
            <div className="limbo-input">
              <Form.Control
                type="text"
                value={seeds?.seeds?.seeds?.serverSeed}
              />
              <div className="limbo-input-btn">
                <Button className="limbo-btn-copy">
                  <img src={copy} alt="copy" />
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Total bets made with pair</Form.Label>
            <div className="limbo-input">
              <Form.Control type="number" value={seeds?.seeds?.seeds?.nonce} />
              <div className="limbo-input-btn">
                <Button className="limbo-btn-copy">
                  <img src={copy} alt="copy" />
                </Button>
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
      {/* <div className='seed-bottom'>
        <h4>Rotate Seed Pair</h4>
        <Form.Group>
          <Form.Label>New Client Seed</Form.Label>
          <div className='limbo-input limbo-nobg-input'>
            <Form.Control type='text' />
            <div className='limbo-change'>
              <Button>Change</Button>
            </div>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Next Server Seed (Hashed)</Form.Label>
          <div className='limbo-input'>
            <Form.Control type='number' />
            <div className='limbo-input-btn'>
              <Button className='limbo-btn-copy'>
                <img src={copy} alt='copy' />
              </Button>
            </div>
          </div>
        </Form.Group>
      </div> */}
    </div>
  );
};

const Varify = () => {
  return (
    <div className="varify-content">
      <div className="varify-box">
        {/* hjhj */}
        {/* <span>{seeds.finalResult}x</span> */}
      </div>

      <Form>
        <div className="tab-box">
          <Form.Group>
            <div className="tab-box-game">
              <Form.Label>Game</Form.Label>
              <select>
                <option>limbo</option>
                <option>dice</option>
                <option>crash</option>
                <option>plinko</option>
              </select>
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Client Seed</Form.Label>
            <div className="limbo-input">
              <Form.Control
                type="text"
                //   value={seeds.seeds.seeds.clientSeed}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Server Seed</Form.Label>
            <div className="limbo-input">
              <Form.Control type="text" />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className="limbo-input">
              <Form.Control
                type="number"
                //    onChange={seeds.handleSetNonce}
              />
              <div className="limbo-input-btn arrow-bottom">
                <Button className="limbo-btn-copy">
                  <img src={arrow} alt="arrow" />
                </Button>
              </div>
              <div className="limbo-input-btn arrow-top">
                <Button className="limbo-btn-copy">
                  <img src={arrow} alt="arrow" />
                </Button>
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
      <div
        className="varify-bottom-text"
        //   onClick={seeds.fairnessCalculate}
      >
        <span>View Calculation Breakdown</span>
      </div>
      <div className="final_result">
        Final result:
        {/* {seeds.finalResult} */}
      </div>
    </div>
  );
};
