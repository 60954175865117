import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { slotInstance } from '../config/axios';
import { toast } from 'react-toastify';
import { client } from '../config/keys';


const initialState = {
  userData: {
    wallet: 0,
    ticket: 0,
  },
  rate: 0,
  apifails: '',
  currentGameUsers: [],
  selectedCurrency: {
    currency: 'BTC',
    walletAmount: 0,
    blockchain: 'btc',
  },
  slot: {
    pair: [],
    numberArray: [],
  },
  gameHistory: [],
  winHistory: [],
  lossHistory: [],
  historyCount: 0,
  gamesBets: [],
  lastBetsCount: 0,
  userBets: [],
  userLastBetsCount: 0,
};

// get user Profile
export const getUserProfile = createAsyncThunk(
  'game/getUserProfile',
  async ({ rejectWithValue }) => {
    try {
      const res = await slotInstance().get('/getUserProfile');
      return {
        userData: res?.data,
      };
    } catch (err) {
      // localStorage.removeItem("usertoken");
      return rejectWithValue({
        msg: err.response.data.msg ?? err.response.data.error,
      });
    }
  }
);

// join game user
export const joinGame = createAsyncThunk(
  'slot/joinGame',
  async (data, { rejectWithValue }) => {
    try {
      const res = await slotInstance().post('/joinGame', data);
      if (res.data.msg === 'Success') {
        return {
          slotData: res?.data,
          winAmount: res?.data.winAmount,
          betAmount: data.amount,
        };
      }
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg ?? 'some error occured ',
        code: err.response.data.code,
        amount: data.amount,
        blockchain: data.blockchain,
      });
    }
  }
);

export const slotSlice = createSlice({
  name: 'slot',
  initialState,
  reducers: {
    resetSlot: (state) => {
      state.slot = {
        pair: [],
      };
    },
    gameSound: (state, action) => {
      state.playSound = action.payload;
    },
    clearStateAfterLogout: (state) => {
      state.userData = {};
      state.userBets = [];
    },

    Changecurrency: (state, action) => {
      state.selectedCurrency.currency = action.payload.curr;
      state.selectedCurrency.walletAmount = action.payload.amount;
      state.selectedCurrency.blockchain = action.payload.blockchain;
    },
    checkRunning: (state, action) => {
      state.runningGame = action.payload;
    },
    updateApi: (state, action) => {
      state.apifails = action.payload;
    },
    updateWallet: (state, { payload }) => {
      state.userData.wallet = state.userData.wallet + payload;
    },
    updateTicket: (state, { payload }) => {
      state.userData.ticket = state.userData.ticket + payload;
    },
  },
  extraReducers: (builder) => {
    // getUserProfile   slice

    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      const userData = payload?.userData?.user || { wallet: 0, ticket: 0 };
      return /* state = */ {
        ...state,
        userData,
      };
    });

    builder.addCase(getUserProfile.rejected, () => {
      window.location.href = client;
    });

    builder.addCase(joinGame.fulfilled, (state, { payload }) => {
      const { slotData } = payload && payload;
      if (payload?.winAmount && payload?.winAmount > 0) {
        state.userData.ticket = state.userData.ticket + payload?.winAmount;
      }
      state.userData.wallet = state.userData.wallet - payload?.betAmount;
      state.slot = slotData;
    });

    builder.addCase(joinGame.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      state.apifails = 'fail';
      toast.error(msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 1,
      });
    });
  },
});

export const {
  resetSlot,
  gameSound,
  updateWallet,
  updateTicket,
  clearStateAfterLogout,
  Changecurrency,
  checkRunning,
  updateApi,
} = slotSlice.actions;

export default slotSlice.reducer;
