import React from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import PropTypes from "prop-types";
import close from "../../assets/images/header/close.svg";
import imageA from "../../assets/images/slot/items/item-2.png";
import imageK from "../../assets/images/slot/items/item-3.png";
import imageQ from "../../assets/images/slot/items/item-4.png";
import imageJ from "../../assets/images/slot/items/item-5.png";
import image10 from "../../assets/images/slot/items/item-6.png";
import imageRabbit from "../../assets/images/slot/items/item-1.png";
import imageDear from "../../assets/images/slot/items/item-777.png";
import playline1 from "../../assets/images/slot/winlines/winframe1.png";
import playline2 from "../../assets/images/slot/winlines/winframe2.png";
import playline3 from "../../assets/images/slot/winlines/winframe3.png";
import playline4 from "../../assets/images/slot/winlines/winframe4.png";
import playline5 from "../../assets/images/slot/winlines/winframe5.png";
import playline6 from "../../assets/images/slot/winlines/winframe6.png";
import playline7 from "../../assets/images/slot/winlines/winframe7.png";
import playline8 from "../../assets/images/slot/winlines/winframe8.png";
import playline9 from "../../assets/images/slot/winlines/winframe9.png";
import playline10 from "../../assets/images/slot/winlines/winframe10.png";
import playline11 from "../../assets/images/slot/winlines/winframe11.png";
import playline12 from "../../assets/images/slot/winlines/winframe12.png";
import playline13 from "../../assets/images/slot/winlines/winframe13.png";
import playline14 from "../../assets/images/slot/winlines/winframe14.png";
import playline15 from "../../assets/images/slot/winlines/winframe15.png";
import playline16 from "../../assets/images/slot/winlines/winframe16.png";
import playline17 from "../../assets/images/slot/winlines/winframe17.png";
import playline18 from "../../assets/images/slot/winlines/winframe18.png";
import playline19 from "../../assets/images/slot/winlines/winframe19.png";
import playline20 from "../../assets/images/slot/winlines/winframe20.png";
import playline21 from "../../assets/images/slot/winlines/winframe21.png";
import playline22 from "../../assets/images/slot/winlines/winframe22.png";
import playline23 from "../../assets/images/slot/winlines/winframe23.png";
import playline24 from "../../assets/images/slot/winlines/winframe24.png";
import playline25 from "../../assets/images/slot/winlines/winframe25.png";
import playline26 from "../../assets/images/slot/winlines/winframe26.png";
import playline27 from "../../assets/images/slot/winlines/winframe27.png";
import playline28 from "../../assets/images/slot/winlines/winframe28.png";
import playline29 from "../../assets/images/slot/winlines/winframe29.png";
import playline30 from "../../assets/images/slot/winlines/winframe30.png";
import playline31 from "../../assets/images/slot/winlines/winframe31.png";
import playline32 from "../../assets/images/slot/winlines/winframe32.png";
import playline33 from "../../assets/images/slot/winlines/winframe33.png";
import playline34 from "../../assets/images/slot/winlines/winframe34.png";
import playline35 from "../../assets/images/slot/winlines/winframe35.png";
import playline36 from "../../assets/images/slot/winlines/winframe36.png";
import playline37 from "../../assets/images/slot/winlines/winframe37.png";
import playline38 from "../../assets/images/slot/winlines/winframe38.png";
import playline39 from "../../assets/images/slot/winlines/winframe39.png";
import playline40 from "../../assets/images/slot/winlines/winframe40.png";
import playline41 from "../../assets/images/slot/winlines/winframe41.png";
import playline42 from "../../assets/images/slot/winlines/winframe42.png";
import playline43 from "../../assets/images/slot/winlines/winframe43.png";
import playline44 from "../../assets/images/slot/winlines/winframe44.png";
import playline45 from "../../assets/images/slot/winlines/winframe45.png";
import playline46 from "../../assets/images/slot/winlines/winframe46.png";
import playline47 from "../../assets/images/slot/winlines/winframe47.png";
import playline48 from "../../assets/images/slot/winlines/winframe48.png";
import playline49 from "../../assets/images/slot/winlines/winframe49.png";
import playline50 from "../../assets/images/slot/winlines/winframe50.png";

const Stats = ({ show, handleClose }) => {
  let statsArr = [
    imageA,
    imageK,
    imageQ,
    imageJ,
    image10,
    imageRabbit,
    imageDear,
  ];
  let playlineArr = [
    playline1,
    playline2,
    playline3,
    playline4,
    playline5,
    playline6,
    playline7,
    playline8,
    playline9,
    playline10,
    playline11,
    playline12,
    playline13,
    playline14,
    playline15,
    playline16,
    playline17,
    playline18,
    playline19,
    playline20,
    playline21,
    playline22,
    playline23,
    playline24,
    playline25,
    playline26,
    playline27,
    playline28,
    playline29,
    playline30,
    playline31,
    playline32,
    playline33,
    playline34,
    playline35,
    playline36,
    playline37,
    playline38,
    playline39,
    playline40,
    playline41,
    playline42,
    playline43,
    playline44,
    playline45,
    playline46,
    playline47,
    playline48,
    playline49,
    playline50,
  ];
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="info-popup"
      centered
    >
      {/* stats-close */}
      <div className="close-icon stats-close" onClick={handleClose}>
        <img src={close} alt="close" />
      </div>
      {/* slot_stats */}
      <Modal.Body>
        <div className="slot-info slot_stats">
          <div className="slot-info-content">
            <Tabs
              defaultActiveKey="rule"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="rule" title="RULES">
                <div className="payroll">
                  <div className="payroll_help">
                    <h3 className="ms-4 mb-4">Rules</h3>
                    <ul>
                      <li>
                        All symbols pay from left to right. Potential winnings
                        are displayed within a game’s paytable.
                      </li>
                      <li>
                        {" "}
                        Joker symbols can substitute for any symbol within a
                        game, excluding the Wild symbol.
                      </li>
                      <li>
                        {" "}
                        Should multiple Joker symbols appear on a winning pay
                        line, only the highest multiplier will be paid. If
                        multipliers are equal, only one prize will be paid.
                      </li>{" "}
                      <li>
                        {" "}
                        Winning combinations where the joker replaces another
                        symbol pay double/2x.
                      </li>
                      <li>
                        {" "}
                        All wins are multiplied by bet per line and not by the
                        total bet.
                      </li>
                    </ul>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="paytable " title="PAY TABLE">
                <div className="payroll">
                  <div className="payroll-section">
                    {statsArr.map((item) => {
                      // eslint-disable-next-line react/jsx-key
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <div className="payroll-list">
                          <img src={item} alt="payroll" />
                          <div className="payroll-details">
                            <h6>
                              5 <span>30.00</span>
                            </h6>
                            <h6>
                              4 <span>10.00</span>
                            </h6>
                            <h6>
                              2 <span>3.00</span>
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="playlines" title="PLAY LINES">
                <div className="payroll1">
                  <div className="payroll-section1">
                    {playlineArr.map((item) => {
                      // eslint-disable-next-line react/jsx-key
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <div className="payroll-list">
                          <img src={item} alt="payroll" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default Stats;
Stats.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.any,
};
