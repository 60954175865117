import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import close from "../../assets/images/header/close.svg";

const SlotHelp = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="info-popup"
      centered
    >
      <div className="close-icon stats-close" onClick={handleClose}>
        <img src={close} alt="close" />
      </div>
      <Modal.Body>
        <div className="slot-info slot_stats">
          <div className="slot-info-content">
            <h2>Help</h2>
            <div className="payroll">
              <div className="payroll_help">
                <h3>How to Help You!</h3>
                <ul>
                  <li>
                    79 percent of customers prefer live chat over regular social
                    posts and email interactions. Live streaming your game on
                    platforms like Twitch and YouTube Gaming is a great
                    initiative to interact with your audience and build user
                    engagement.
                  </li>
                  <li>
                    {" "}
                    Six out of ten prospects expect a business to have an online
                    presence so they can easily find information related to
                    their search
                  </li>
                  <li>
                    {" "}
                    One of the most inexpensive ways to drive traffic to your
                    website is blogging. Blogs steal the spotlight as one of the
                    top three content marketing strategies.
                  </li>{" "}
                  <li>
                    {" "}
                    Landing pages are standalone web pages that are designed for
                    marketing specific advertisement campaigns.
                  </li>
                  <li>
                    {" "}
                    Internet forums have been around for decades. Known for
                    encouraging discussions and information exchange, forums are
                    one of the best content marketing strategies to improve your
                    brand awareness.
                  </li>
                  <li>
                    {" "}
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SlotHelp;
SlotHelp.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.any,
};
