/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useCallback } from "react";
import sym1 from "../../assets/images/slot/items/item-1.png";
import sym2 from "../../assets/images/slot/items/item-2.png";
import sym3 from "../../assets/images/slot/items/item-3.png";
import sym4 from "../../assets/images/slot/items/item-4.png";
import sym5 from "../../assets/images/slot/items/item-5.png";
import sym6 from "../../assets/images/slot/items/item-6.png";
import sym7 from "../../assets/images/slot/items/item-777.png";
import win_icon from "../../assets/images/slot/winner.png";
import Start from "../../assets/audio/SlotMachineLeverPull_BW.48910.wav";
import Play from "../../assets/audio/click.mp3";
import GamePlay from "../../assets/audio/role-sound.mp3";
import GameWin from "../../assets/audio/Slot Machine Win 1.mp3";
import reelsound from "../../assets/audio/role-sound.mp3";
import Stop from "../../assets/audio/stop.mp3";
import PropTypes from "prop-types";
import "./slot.css";
import livestats from "../../assets/images/game/live.png";
import { toast } from "react-toastify";
import add from "../../assets/images/slot/plus.png";
import remove from "../../assets/images/slot/minus.png";
import { Button, Modal, Form } from "react-bootstrap";
import LeaveConfirmPopup from "./leaveConfirmation";
import BetHistory from "./BetHistory";
import numFormatter from "../../util/utils";
import InputRange from "react-input-range";
import { slotInstance } from "../../config/axios";
import axios from "axios";
import debounce from "lodash.debounce";
import Stats from "./stats";
import SlotHelp from "./slotHelp";
import FainessPopup from "./fairnesspopup";
import LiveStats from "./livestatspop";
import { server } from "../../config/keys";
import { useMediaQuery } from 'react-responsive'
import { useAuthStore } from "../../hooks/auth";

const maximumBet = 100;
let timeoutIndexPair;
const Slot = () => {
  const [allSeeds, setAllSeeds] = useState();
  const [fairness, setFairness] = useState(false);
  const [fairnessResult, setFairnessResult] = useState("");
  const [stats, setStats] = useState(false);
  const [statsData, setStatsData] = useState([]);
  const [testMode, setTestMode] = useState(
    localStorage.getItem("testmode") === "true"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [volume, setVolume] = useState(true);
  const { user } = useAuthStore(state => state);
  const playAudio = (type) => {
    let sound = localStorage.getItem("sound");
    if (sound === true || sound === "true") {
      if (type) {
        const audioEl = document.getElementsByClassName(`audio-${type}`)[0];
        if (audioEl) {
          audioEl.play();
        }

      }
    }
  };
  const handleSound = () => {
    let sound = localStorage.getItem("sound");
    if (sound === "false" || sound === false) {
      localStorage.setItem("sound", true);
      setVolume(true)
    } else {
      localStorage.setItem("sound", false);
      setVolume(false)
    }
  };

  useEffect(() => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      setVolume(true)
    } else {
      localStorage.setItem("sound", false);
      setVolume(false)
    }
  }, [])


  const isLandscape = useMediaQuery({
    query: "(max-height: 480px) and (orientation: landscape)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 767px) and (orientation: portrait)",
  });

  const getStatsData = async () => {
    await slotInstance()
      .get("/getLiveStats")
      .then((response) => {
        setStatsData([response?.data?.bettingData, ...statsData]);
      });
  };

  const handleTestMode = () => {
    const msg = !testMode ? "enabled" : "disabled";
    if (msg === 'disabled') {
      window.parent.postMessage({ type: "testModeDisable", msg: msg, testMode: testMode }, '*')
    } else {
      window.parent.postMessage({ type: "testMode", msg: msg, testMode: testMode }, '*')
    }
    setTestMode(!testMode);
    localStorage.setItem('testmode', !testMode);
  };

  const handlestatsClose = () => {
    setStats(false);
  };

  const handleCloseFairness = () => {
    setFairness(false);
    setFairnessResult("");
  };

  const handleCloseWinpopup = () => {
    setResultPopup(false);
  };


  const handlestatsShow = async () => {
    setStats(true);
  };

  useEffect(() => {
    if (stats) {
      getStatsData();
    }
  }, [stats]);


  const fairnessCalculate = async () => {
    setAllSeeds();
    // if (resultHistory?.length > 0) {
    //   const reqData = {
    //     params: {
    //       clientSeed: clientSeed,
    //       nonce: nonce,
    //       serverSeed: serverSeed,
    //     },
    //   };
    //   await axios.get(`${server}/verify`, reqData).then((response) => {
    //     var responseResult = response?.data;
    //     if(String(parseFloat(responseResult).toFixed(2))===String(parseFloat(result).toFixed(2))){
    //       setFairnessResult(response?.data);
    //     }
    //   });
    // }
  };

  const handleSetNonce = async () => {
  };

  const [slotState, setSlotState] = useState({
    pair: [],
    numberArray: [],
    winAmount: 0,
  });
  const [animateReel, setAnimateReel] = useState(false);
  const [animateReelReset, setAnimateReelReset] = useState(false);
  const [animateReelJump, setAnimateReelJump] = useState(false);
  const { pair, numberArray, winAmount } = slotState;
  // const playSound = gameObj.playSound;
  const [gameStatus, setGameStatus] = useState("init");
  const [resultPopup, setResultPopup] = useState(false);
  const [running, setRunning] = useState(false);
  const [spinner, setSpinner] = useState({ spintime: 0, spinvalue: 0 });
  const [betPopup, setBetPopup] = useState(false);
  const [reset, setReset] = useState(true);
  const IMAGES = [sym1, sym2, sym3, sym4, sym5, sym6, sym7];
  const [preloadImages1, setPreloadImages1] = useState([]);
  const [preloadImages2, setPreloadImages2] = useState([]);
  const [preloadImages3, setPreloadImages3] = useState([]);
  const [preloadImages4, setPreloadImages4] = useState([]);
  const [preloadImages5, setPreloadImages5] = useState([]);
  const [helpshow, sethelpshow] = useState();
  const minimumBet = 1;
  const [betAmount, setBetAmount] = useState(minimumBet);

  const [slotWinAmt, setSlotWinAmt] = useState(
    sessionStorage.getItem("slotWinAmount")
  );

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [confirmExit, setConfirmExit] = useState(false);

  useEffect(() => {
    window.addEventListener("message", messageHandler, false);
    function messageHandler(event) {
      const { action, key, value } = event.data;
      if (action == "save") {
        window.localStorage.setItem(key, JSON.stringify(value));
      } else if (action == "get") {
        event.source.postMessage(
          {
            action: "returnData",
            key,
            value: JSON.parse(window.localStorage.getItem(key)),
          },
          "*"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (pair && pair.length > 0) {
      timeoutIndexPair = setTimeout(() => {
        setIndex(index + 1 === pair.length ? 0 : index + 1);
      }, 1800);
    }

    return () => {
      if (timeoutIndexPair) {
        clearTimeout(timeoutIndexPair);
      }
    };
  }, [pair, index]);

  useEffect(() => {
    if (Array.isArray(numberArray) && numberArray.length) {
      setGameStatus("inGame");
      setSpinner({ spintime: 5, spinvalue: 50 });
      setReset(false);
    }
  }, [numberArray]);

  const shuffleArray = (array) => {
    let sArr = [...array];
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [sArr[currentIndex], sArr[randomIndex]] = [
        sArr[randomIndex],
        sArr[currentIndex],
      ];
    }

    return sArr;
  };

  const shuffleAndUpdateState = (updateState) => {
    let arr = [];
    for (let i = 0; i < 20; i++) {
      const shuffleArray1 = shuffleArray(IMAGES);
      arr = [...shuffleArray1, ...arr];
    }
    updateState(arr);
  };

  if (running) {
    playAudio("Start");
    setTimeout(() => {
      playAudio("GamePlay");
    }, 800);
  } else {
    const audioEl = document.getElementsByClassName(`audio-GamePlay`)[0];
    if (audioEl) {
      audioEl.pause();
    }
  }

  const reShufflePreload = () => {
    shuffleAndUpdateState(setPreloadImages1);
    shuffleAndUpdateState(setPreloadImages2);
    shuffleAndUpdateState(setPreloadImages3);
    shuffleAndUpdateState(setPreloadImages4);
    shuffleAndUpdateState(setPreloadImages5);
  };

  useEffect(() => {
    reShufflePreload();
  }, []);

  const playGameOnTestMode = async () => {
    const payload = {
      amount: 0,
      betLine: 1,
      testMode: true,
    };
    setResultPopup(false);
    setSpinner({ spintime: 0, spinvalue: 0 });
    setSlotState({ pair: [], numberArray: [], winAmount: 0 });
    setIndex(0);
    setRunning(true);
    setTimeout(() => {
      setTimeout(() => {
        setGameStatus("init");
      }, 600);
      setTimeout(async () => {
        try {
          setReset(true);
          const res = await axios.post(
            `${server}/api/slot/joinGame-for-free`,
            payload
          );
          const { msg } = res.data;
          if (res.data.msg === "Success") {
            setSlotState({ ...res.data });
            // setWinpopup(true)
          } else {
            toast.error(msg, { toastId: "slot-join" });
          }
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            toast.error(error.response.data.msg, { toastId: "slot-join" });
          } else {
            toast.error("Some error occured", { toastId: "slot-join" });
          }
        }
      }, 700);
    }, 320);
    return false;
  }

  const handleSpin = async () => {
    if (!testMode && !user) {
      window.parent.postMessage("loginRequest", '*');
      return;
    }

    const minBet = minimumBet;
    if (betAmount < minBet) {
      setErrorMessage(`Minimum bet is ${minBet}`);
      setTimeout(() => {
        setErrorMessage("");
      }, [3000]);
      return;
    }

    if (!testMode) {
      if (user && user?.wallet < betAmount) {
        window.parent.postMessage("walletUpdate", "*");
        return;
      }
    }

    playAudio("Play");
    const audioEl = document.getElementsByClassName(`audio-reelsound`)[0];
    playAudio('reelsound');
    setTimeout(() => {
      audioEl.pause();
    }, 5020);

    setAnimateReelJump(true);
    setAnimateReelReset(true);
    setTimeout(() => {
      setAnimateReel(true);
      setTimeout(() => {
        setAnimateReel(false);
        setAnimateReelJump(false);
        setAnimateReelReset(false);
      }, 5020);
    }, 10);

    if (running) {
      window.parent.postMessage("gameRunning", '*');
      return;
    }

    if (testMode) {
      const isCheck = await playGameOnTestMode();
      if (!isCheck) {
        return
      }
    }

    if (!betAmount || parseFloat(betAmount) <= 0) {
      setErrorMessage(`Please enter bet amount`);
      setTimeout(() => {
        setErrorMessage("");
      }, [3000]);
      return;
    }

    const payload = {
      amount: testMode ? 0 : betAmount,
      betLine: 1,
      testMode: testMode,
    };
    // Clear this state to any state before so
    // the reel transition will end
    // and the win popup will not come immediately
    setResultPopup(false);
    setSpinner({ spintime: 0, spinvalue: 0 });
    setSlotState({ pair: [], numberArray: [], winAmount: 0 });
    setIndex(0);
    // Decrease user ticket
    // Do below task with a bit delay so it will took time to fet the game in ruuning state and the winning popup will not show
    // before the games end
    setRunning(true);
    setTimeout(() => {
      setTimeout(() => {
        setGameStatus("init");
      }, 600);

      setTimeout(async () => {
        try {
          setReset(true);
          const res = await slotInstance().post("/joinGame", payload);
          const { msg } = res.data;
          if (res.data.msg === "Success") {
            setSlotState({ ...res.data });
          } else {
            toast.error(msg, { toastId: "slot-join" });
          }
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            toast.error(error.response.data.msg, { toastId: "slot-join" });
          } else {
            toast.error("Some error occured", { toastId: "slot-join" });
          }
        }
      }, 700);
    }, 320);
  };

  const getImage = useCallback((number) => {
    switch (number) {
      case 0:
        return sym1;
      case 1:
        return sym2;
      case 2:
        return sym3;
      case 3:
        return sym4;
      case 4:
        return sym5;
      case 5:
        return sym6;
      case 6:
        return sym7;
    }
  }, []);

  const increaseBetAmount = () => {
    playAudio("Play")
    const increaseBetAmountBy = minimumBet;
    const totalBetAmount = betAmount + increaseBetAmountBy;
    const increaseBetAmount = maximumBet;
    if (betAmount >= increaseBetAmount) {
      setErrorMessage(`Maximum bet is $${maximumBet}`);
      setTimeout(() => {
        setErrorMessage("");
      }, [3000]);
      return;
    }
    if (totalBetAmount > maximumBet) {
      return;
    }
    setBetAmount(totalBetAmount);
  };

  const rangeBetAmount = (e) => {
    playAudio("Play")
    const rangeBetAmountBy = e * minimumBet;
    const totalBetAmount = betAmount + rangeBetAmountBy;
    if (totalBetAmount > maximumBet) {
      return;
    }
    setBetAmount(totalBetAmount);
  };

  const decreaseBetAmount = () => {
    const decreaseBetAmount = minimumBet;
    if (betAmount - decreaseBetAmount < decreaseBetAmount) {
      setErrorMessage(`Minimum bet is $ ${minimumBet}`);
      setTimeout(() => {
        setErrorMessage("");
      }, [3000]);
      return;
    }
    if (betAmount > 0) {
      setBetAmount(betAmount - decreaseBetAmount);
      playAudio("Play")
    }
  };

  const maxBetAmount = () => {
    setBetAmount(maximumBet);
    playAudio("Play")
  };

  const clearBet = () => {
    setBetAmount(() => minimumBet);
    playAudio("Play")
  };

  const handleClose = () => {
    setShow(!show);
  };

  const helphandleClose = () => {
    sethelpshow(!helpshow);
  };

  useEffect(() => {
    if (!user) {
      if (testMode) {
        setResultPopup(false);
        return;
      }
    }

    if (!testMode) {
      if (resultPopup && winAmount > 0) {
        playAudio("GameWin");
        let timeout = setTimeout(() => {
          setResultPopup(false);
        }, 4000);

        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    } else {
      if (resultPopup) {
        playAudio("GameWin")
        let timeout = setTimeout(() => {
          setResultPopup(false);
        }, 4000);
        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    }
  }, [resultPopup, winAmount]);

  useEffect(() => {
    if (winAmount && resultPopup) {
      const prevBetSession = sessionStorage.getItem("slotWinAmount")
        ? sessionStorage.getItem("slotWinAmount") * 1
        : 0;
      const newWINAmount = parseFloat(winAmount);
      const roundOff = newWINAmount + prevBetSession;
      sessionStorage.setItem("slotWinAmount", roundOff.toFixed(2));
      setSlotWinAmt(roundOff.toFixed(2));
    }
  }, [winAmount, resultPopup]);

  const handleBetPopup = () => {
    setBetPopup(!betPopup);
  };

  // Dynamic screen scaling

  const initialScale =
    window.innerWidth / (window.innerWidth < 991 ? 20 : 19.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);
  const [topValue, setTopValue] = useState(initialTop);
  console.log('topValue----->', topValue);
  useEffect(() => {
    setScaleValue(initialScale);
    setTopValue(initialTop);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    const currentScreenSize = e.target.innerWidth;
    setScaleValue(currentScreenSize / (currentScreenSize < 991 ? 20 : 19.2));
    setTopValue(
      (100 - currentScreenSize / (currentScreenSize < 991 ? 20 : 19.2)) / 2
    );
  };

  // stats open
  const [slotheight, setSlotheight] = useState();
  const stats_height = () => {
    setSlotheight(!slotheight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="wrapper">
      <video id="background-video" autoPlay loop muted>
        <source
          src="https://cdn.dribbble.com/userupload/3820544/file/original-d9f7f37de39ab8615d574d3664cb66d2.mp4"
          type="video/mp4"
        />
      </video>
      <div className="slot-game-wrapper">
        <div className="container">
          <div className="slot-game-grid">
            <div className="slot-game-tab">
              <div className="slot-game-testmode">
              </div>
              <div className="slot-btn-box">
                <Button onClick={handleClose} className="rules-btn">Rules</Button>
                <Button className="slot-btn-bar" onClick={stats_height}>
                  <i className="bi bi-distribute-vertical"></i>
                </Button>
              </div>
              <div
                className={` game-bet-section ${slotheight ? "game-bet-section-height" : ""
                  }`} >
                <div className="game-field-input">
                  Win <span>{slotWinAmt ? slotWinAmt : 0}</span>
                </div>

                <div className="game-field-input">
                  Bet <span>{numFormatter(betAmount)}</span>
                </div>
              </div>

              <div
                className={`slot-start-btn-box ${running ? "disable-box" : ""}`}
              >
                <Button onClick={debounce(handleSpin, 400)}>
                  {running ? "Stop" : "Start"}
                </Button>
              </div>

              <div className="slot-game-footer slot-game-footer_bottom">
                <div
                  className={`slot-game-footer-content ${running ? "disable-box" : ""
                    }`}
                >

                  <div className="bet-amount">
                    <div className="increment-box">
                      <img
                        onClick={() => decreaseBetAmount()}
                        src={remove}
                        alt="add"
                      />
                      <InputRange
                        maxValue={maximumBet}
                        minValue={1}
                        value={betAmount}
                        onChange={(e) => {
                          if (!running) {
                            setBetAmount(e);
                          }
                        }}
                        onChangeComplete={(betAmt) => {
                          if (!running) {
                            rangeBetAmount(betAmt);
                            setBetAmount(betAmt);
                          }
                        }}
                      />
                      <img
                        onClick={() => increaseBetAmount()}
                        src={add}
                        alt="remove"
                      />
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      if (betAmount == maximumBet) {
                        clearBet();
                      } else {
                        maxBetAmount();
                      }
                    }}
                  >
                    {betAmount == maximumBet ? "Min Bet" : "Max Bet"}
                  </Button>
                  {errorMessage && <p className="text-danger">{errorMessage}</p>}
                </div>

                <div className="testmode">
                  <div className="auto-bet auto-bet-mode">
                    <p>Just for fun</p>
                    <div
                      className={`toggle-section ${running ? "disable-box" : ""}`}
                    >
                      <input
                        type="checkbox"
                        id="lb3"
                        checked={testMode}
                        onChange={handleTestMode}
                      />
                      <Form.Label htmlFor="lb3" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="slot-game-footer slot-game-footer-bottom ">
                <div className="volume-btn">
                  {volume ? (
                    <i
                      className="las la-volume-up"
                      role="presentation"
                      onClick={handleSound}
                    />
                  ) : (

                    <i
                      className="las la-volume-off"
                      role="presentation"
                      onClick={handleSound}
                    />
                  )}
                </div>
                <div className="slot-game-btns">
                  <div className="fairness-btn" onClick={handlestatsShow}>
                    <img src={livestats} alt="livestats" />
                    <span>Live stats</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="slot-game">
              <div className="slot-game-content">
                <div
                  className="slot-container"
                  style={{
                    transform: isMobile
                      ? `translate(-50%, 0%) scale(${(scaleValue * 1.3) / 100})`
                      : isLandscape
                        ? `translate(-50%, 0%) scale(${(scaleValue * 0.9) / 100})`
                        : `translate(-50%, -2%) scale(${(scaleValue * 0.9) / 100
                        })`,
                  }}
                >
                  <div id="slotContainer">
                    <div className="reel-content">
                      <div id="reels">
                        {preloadImages1 &&
                          preloadImages1.length > 0 &&
                          preloadImages2 &&
                          preloadImages2.length > 0 &&
                          preloadImages3 &&
                          preloadImages3.length > 0 ? (
                          <>
                            <ShowResultImages
                              pair={pair}
                              numberArray={numberArray}
                              rowIndex={0}
                              getImage={getImage}
                              preloadImages={preloadImages1}
                              setRunning={null}
                              setShowLine={null}
                              index={index}
                              gameStatus={gameStatus}
                              spintime={spinner.spintime}
                              spinvalue={spinner.spinvalue}
                              reset={reset}
                              setResultPopup={null}
                              updateTicketAmount={null}
                              running={running}
                              animateReel={animateReel}
                              animateReelReset={animateReelReset}
                              animateReelJump={animateReelJump}
                            />

                            <ShowResultImages
                              pair={pair}
                              numberArray={numberArray}
                              rowIndex={1}
                              getImage={getImage}
                              preloadImages={preloadImages2}
                              setRunning={null}
                              setShowLine={null}
                              index={index}
                              gameStatus={gameStatus}
                              spintime={spinner.spintime}
                              spinvalue={spinner.spinvalue}
                              reset={reset}
                              setResultPopup={null}
                              updateTicketAmount={null}
                              running={running}
                              animateReel={animateReel}
                              animateReelReset={animateReelReset}
                              animateReelJump={animateReelJump}
                            />

                            <ShowResultImages
                              pair={pair}
                              numberArray={numberArray}
                              rowIndex={2}
                              getImage={getImage}
                              preloadImages={preloadImages3}
                              // if we don't pass setRunning here it will not restart the game
                              setRunning={null}
                              setShowLine={null}
                              index={index}
                              gameStatus={gameStatus}
                              spintime={spinner.spintime}
                              spinvalue={spinner.spinvalue}
                              reset={reset}
                              setResultPopup={null}
                              updateTicketAmount={null}
                              running={running}
                              animateReel={animateReel}
                              animateReelReset={animateReelReset}
                              animateReelJump={animateReelJump}
                            />

                            <ShowResultImages
                              pair={pair}
                              numberArray={numberArray}
                              rowIndex={3}
                              getImage={getImage}
                              preloadImages={preloadImages4}
                              setRunning={null}
                              setShowLine={null}
                              index={index}
                              gameStatus={gameStatus}
                              spintime={spinner.spintime}
                              spinvalue={spinner.spinvalue}
                              reset={reset}
                              setResultPopup={null}
                              updateTicketAmount={null}
                              running={running}
                              animateReel={animateReel}
                              animateReelReset={animateReelReset}
                              animateReelJump={animateReelJump}
                            />
                            <ShowResultImages
                              pair={pair}
                              numberArray={numberArray}
                              rowIndex={4}
                              getImage={getImage}
                              preloadImages={preloadImages5}
                              setRunning={setRunning}
                              running={running}
                              index={index}
                              gameStatus={gameStatus}
                              spintime={spinner.spintime}
                              spinvalue={spinner.spinvalue}
                              setResultPopup={setResultPopup}
                              resultPopup={resultPopup}
                              updateTicketAmount={null}
                              animateReel={animateReel}
                              animateReelReset={animateReelReset}
                              animateReelJump={animateReelJump}
                            />
                          </>
                        ) : (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* user &&
                <div className="slot-game-footer">
                  <div className="slot-game-btns">
                    <div className="fairness-btn" onClick={handlestatsShow}>
                      <img src={livestats} alt="livestats" />
                      <span>Live stats</span>
                    </div>
                  </div>
                </div>} */}
            </div>
          </div>
        </div>
      </div>


      <div className="audio-box">
        <audio className="audio-reelsound" muted={!volume} src={reelsound}></audio>
        <audio className="audio-Play" muted={!volume} src={Play}></audio>
        <audio className="audio-GameWin" muted={!volume} src={GameWin}></audio>
        <audio className="audio-GamePlay" muted={!volume} src={GamePlay}></audio>
        <audio className="audio-Start" muted={!volume} src={Start}></audio>
        <audio className="audio-Stop" muted={!volume} src={Stop}></audio>

        {resultPopup && (
          <div
            className={`${testMode === false
              ? winAmount > 0
                ? "winning-animation winning-bg-animation"
                : ""
              : "winning-animation winning-bg-animation"
              }`}
            onClick={handleCloseWinpopup}
          >
            {testMode === false ? (
              winAmount > 0 ? (
                <div className="winning-amount winns win-amount-section">
                  <div className="flex-win">
                    <p>Winning Amount</p>
                    <h2>$ {winAmount?.toFixed(2)}</h2>
                  </div>
                  <div className="flex-win-graphic">
                    <img src={win_icon} alt="" />
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              <div className={`winning-amount winns win-amount-section `}>
                <div className="flex-win">
                  <p>Winning Amount</p>
                  <h2>$ {0.0}</h2>
                </div>
                <div className="flex-win-graphic">
                  <img src={win_icon} alt="" />
                </div>
              </div>
            )}
            <div className="before"></div>
            <div className="after"></div>
          </div>
        )}
      </div>

      {/* <SlotInfo show={show} handleClose={handleClose} /> */}
      <Stats show={show} handleClose={handleClose} />
      <SlotHelp show={helpshow} handleClose={helphandleClose} />
      <LeaveConfirmPopup
        setConfirmExit={setConfirmExit}
        confirmExit={confirmExit}
      />
      <BetHistory show={betPopup} handleClose={handleBetPopup} />

      <Modal
        show={fairness}
        onHide={handleCloseFairness}
        centered
        className="fairness-popup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Fairness</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FainessPopup
            seeds={allSeeds}
            finalResult={fairnessResult}
            fairnessCalculate={fairnessCalculate}
            handleSetNonce={handleSetNonce}
          />
        </Modal.Body>
      </Modal>
      {/* live stats */}
      <Modal
        show={stats}
        onHide={handlestatsClose}
        centered
        className="fairness-popup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Live Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LiveStats
            statsData={statsData}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

// Row index refer to the reel like first reel or second reel
const ShowResultImages = ({
  index,
  pair,
  numberArray,
  preloadImages,
  rowIndex,
  getImage,
  setRunning,
  setShowLine,
  gameStatus,
  spintime,
  spinvalue,
  reset,
  setResultPopup,
  running,
  animateReel,
  animateReelReset,
  animateReelJump,
}) => {
  const getActiveImages = (i, row) => {
    let winnerClass = "";

    if (reset) {
      return "";
    }

    if (pair && pair.length) {
      // You have an array
      if (
        pair.length > 0 &&
        pair[index] &&
        Object.values(pair[index]).find(
          (el) => el?.column === row && el.row === i
        )
      ) {
        return "pair-game";
      }
    }
    return winnerClass;
  };

  return (
    <>
      <div
        className={`reel ${animateReelJump ? "wow animate__animated animate__rubberBand" : ""
          }`}
      >
        <div
          className={`reel-box ${animateReel ? "reel-start" : ""} ${animateReelReset ? "reel-reset" : ""
            }`}
          style={{
            transform: `translate3d(0px, ${spinvalue}%, 0px) translateX(0)`,
            transitionDuration: `${spintime}s`,
          }}
          onTransitionEnd={() => {
            if (setResultPopup && running) {
              setResultPopup(true);
              // updateTicketAmount();
            }
            if (setRunning) {
              setRunning(false);
            }

            if (setShowLine) {
              setShowLine(true);
            }
          }}
        >
          {preloadImages.length > 0 &&
            preloadImages.map((el, i) => (
              <div
                className={`slot-items ${!running ? getActiveImages(i - 66, rowIndex) : ""
                  }`}
              >
                <img
                  className={`${gameStatus === "inGame" ? "" : ""}`}
                  key={`HERE--${i}`}
                  src={
                    numberArray && numberArray.length && i >= 66 && i <= 69
                      ? getImage(numberArray[i - 66][rowIndex])
                      : i === 2
                        ? preloadImages[1]
                        : i === 3
                          ? preloadImages[0]
                          : el
                  }
                  alt={i}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

ShowResultImages.propTypes = {
  index: PropTypes.number,
  pair: PropTypes.array,
  numberArray: PropTypes.array,
  preloadImages: PropTypes.array,
  rowIndex: PropTypes.number,
  getImage: PropTypes.any,
  setRunning: PropTypes.any,
  setShowLine: PropTypes.any,
  gameStatus: PropTypes.string,
  spinvalue: PropTypes.any,
  spintime: PropTypes.any,
  reset: PropTypes.any,
  setResultPopup: PropTypes.any,
  running: PropTypes.any,
  updateTicketAmount: PropTypes.any,
  animateReel: PropTypes.any,
  animateReelReset: PropTypes.any,
  animateReelJump: PropTypes.any,
  handleClose: PropTypes.any,
};

export default Slot;


