/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Tabs, Tab, Modal, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import close from "../../assets/images/header/close.svg";
import useGetBets from "../../hooks/getBets";
import { timeDifference } from "../../util/timeEclapse";
import { useAuthStore } from "../../hooks/auth";

const BetHistory = ({ show, handleClose }) => {
  const { user } = useAuthStore(state => state);
  const [activeState] = useState("My Bets");
  const [allBets, allBetLoader, fetchAllBet, isAllBetDataEnd, allBetPage] =
    useGetBets("all");
  const [myBets, myBetLoader, fetchMyBet, isMyBetDataEnd, myBetPage] =
    useGetBets("mybets");
  const [winBets, winBetLoader, fetchWinBet, isWinBetDataEnd, winBetPage] =
    useGetBets("winBets");
  const [loseBets, loseBetLoader, fetchloseBet, isloseBetDataEnd, loseBetPage] =
    useGetBets("loseBets");

  useEffect(() => {
    if (user) {
      fetchAllBet();
      fetchMyBet();
      fetchWinBet();
      fetchloseBet();
    }

  }, [user]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="info-popup"
      centered
    >
      <div className="close-icon" onClick={handleClose}>
        <img src={close} alt="close" />
      </div>
      <Modal.Body>
        <div className="bottom-table">
          <Tabs
            defaultActiveKey={activeState}
            id={activeState}
            className="mb-3"
          >
            <Tab eventKey="My Bets" title="My Bets" className="profile-tabs">
              <ShowData
                allBets={myBets}
                allBetLoader={myBetLoader}
                allBetPage={myBetPage}
                isAllBetDataEnd={isMyBetDataEnd}
                fetchAllBet={fetchMyBet}
              />
            </Tab>
            <Tab eventKey="All Bets" title="All Bets" className="profile-tabs">
              <ShowData
                allBets={allBets}
                allBetLoader={allBetLoader}
                allBetPage={allBetPage}
                isAllBetDataEnd={isAllBetDataEnd}
                fetchAllBet={fetchAllBet}
              />
            </Tab>

            <Tab eventKey="Win" title="Win" className="profile-tabs">
              <ShowData
                allBets={winBets}
                allBetLoader={winBetLoader}
                allBetPage={winBetPage}
                isAllBetDataEnd={isWinBetDataEnd}
                fetchAllBet={fetchWinBet}
              />
            </Tab>
            <Tab eventKey="Lose" title="Lose" className="profile-tabs">
              <ShowData
                allBets={loseBets}
                allBetLoader={loseBetLoader}
                allBetPage={loseBetPage}
                isAllBetDataEnd={isloseBetDataEnd}
                fetchAllBet={fetchloseBet}
              />
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ShowData = ({
  allBets,
  allBetLoader,
  fetchAllBet,
  isAllBetDataEnd,
  allBetPage,
}) => {
  return (
    <>
      <Table bordered responsive variant="dark">
        <thead>
          <tr>
            <th>Username</th>
            <th>Bet Amount</th>
            <th>Result</th>
            <th>Amount</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {allBetLoader ? (
            <div className="table-loader">
              <p>Loading...</p>
            </div>
          ) : (
            allBets &&
            !!allBets?.length &&
            allBets.map((el) => (
              // eslint-disable-next-line react/jsx-key
              <tr key={el._id} className="pointer-cursor">
                <td>{el.userid.username} </td>
                <td>{(el.betAmount).toFixed(2)} </td>
                <td
                  className={`${el.totalWin >= 0 ? "user-win" : "user-lose"}`}
                >
                  {el.totalWin >= 0 ? "Win" : "loss"}{" "}
                </td>
                <td
                  className={`${el.totalWin >= 0 ? "user-win" : "user-lose"}`}
                >
                  {(el.totalWin).toFixed(2)}{" "}
                </td>
                <td>{timeDifference(el.createdAt)}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <div className="table-pagination-wrapper">
        <button
          className="btn btn-primary"
          onClick={() => fetchAllBet(-1)}
          disabled={allBetPage === 1}
        >
          {"<<"}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => fetchAllBet(1)}
          disabled={isAllBetDataEnd}
        >
          {">>"}
        </button>
      </div>
    </>
  );
};

BetHistory.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default BetHistory;
