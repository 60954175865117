import { useState } from 'react';
import { slotInstance } from '../config/axios';
import debounce from 'lodash.debounce';

const useGetBets = (betType = 'all') => {
  const [isDataEnd, setIsDataEnd] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = debounce(async (value = 0) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const resp = await slotInstance().get(
        `/getBets?page=${page}&type=${betType}`
      );
      const { dataEnd, data: betData } = resp.data;
      setPage(value + page);
      setIsDataEnd(!!dataEnd || betData.length !== 10);
      if (betData.length) {
        setData(betData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, 500);

  return [data, loading, fetchData, isDataEnd, page];
};

export default useGetBets;
