export const timeDifference = (time1) => {
  const current = new Date();
  const previous = new Date(time1);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} seconds ago`;
  }
  if (elapsed < msPerHour) {
    const totalMinute = Math.round(elapsed / msPerMinute);
    return `${totalMinute} ${totalMinute > 1 ? 'minutes' : 'minute'}  ago`;
  }
  if (elapsed < msPerDay) {
    const totalHour = Math.round(elapsed / msPerHour);
    return `${totalHour} ${totalHour > 1 ? 'hours' : 'hour'}  ago`;
  }
  if (elapsed < msPerMonth) {
    const totalDay = Math.round(elapsed / msPerDay);
    return `${totalDay} ${totalDay > 1 ? 'days' : 'day'}  ago`;
  }
  if (elapsed < msPerYear) {
    const totalMonth = Math.round(elapsed / msPerMonth);
    return `${totalMonth} ${totalMonth > 1 ? 'months' : 'month'} ago`;
  }
  const totalYear = Math.round(elapsed / msPerYear);
  return `${totalYear}  ${totalYear > 1 ? 'years' : 'year'} ago`;
};
