
import { produce } from 'immer'
import create from 'zustand'
import { toast } from 'react-toastify'
import { userInstance } from '../config/axios'


export const useAuthStore = create((setState) => ({
  user: null,
  isAuthLoading: false,
  isAuth: false,
  
  signIn: async () => {
    try {
      setState(state => ({ ...state, isAuthLoading: true }))
      const res = await userInstance().get('', { withCredentials: true, credential: 'include'});
      if(res.data){
        setState(produce(state => {
          state.user = res.data
          state.isAuth = true
          state.isAuthLoading = false
        }))
      }
      setState(state => ({ ...state, isLoading: false }))
    } catch (error) {
      setState(state => ({...state, user: null, isAuth: false}))
    }
  },
  setUser: (user) => {
    try {
      setState(
        produce(state => {
          state.user = user
          state.isAuth = true
          state.isAuthLoading = false
        })
      )
    } catch (error) {
      toast.error('update user data')
      console.error('setUserError', error)
    }
  }
}))